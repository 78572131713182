<template>
  <div>
    <section>
      <b-row>
        <b-col cols="12" md="4"
          ><b-form-input
            v-model="searchValue"
            @input="onSearch"
            placeholder="search..."
          ></b-form-input
        ></b-col>
        <b-col cols="6" md="6"> </b-col>
        <b-col v-if="is_add" cols="6" md="2">
          <b-button block @click="onAddCity" variant="primary">
            Add City
          </b-button>
        </b-col>
      </b-row>
    </section>
    <b-card no-body header-tag="header" footer-tag="footer">
      <b-card-text>
        <b-table
          bordered
          responsive
          :fields="fields"
          :items="items"
          :busy="isBusy"
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(sr)="data">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
          </template>

          <template #cell(city_is_active)="data">
            {{ data.item.city_is_active == "Y" ? "Active" : "Disabled" }}
          </template>

          <template v-if="is_update" #cell(action)="data">
            <b-button
              @click="onEditItem(data.item)"
              size="sm"
              variant="outline-primary"
              >Edit</b-button
            >
          </template>
        </b-table>
      </b-card-text>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
    <!-- no-close-on-esc
      no-close-on-backdrop -->
    <b-sidebar
      right
      v-model="showSideBar"
      id="sidebar-backdrop"
      title="City"
      backdrop-variant="dark"
      backdrop
      shadow
    >
      <div class="px-2 py-1">
        <b-form-group label="City Name">
          <b-form-input
            v-model="form.city_name"
            placeholder="City Name"
            id="input-default"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Country Name">
          <v-select
            :clearable="false"
            v-model="form.country_id"
            label="country_name"
            value="country_id"
            :reduce="(country) => country.country_id"
            placeholder="Select Country"
            :options="countryListArray"
          />
        </b-form-group>
        <b-form-group label="City Status">
          <b-form-checkbox v-model="form.city_is_active" switch>{{
            form.city_is_active == true ? "Active" : "Disabled"
          }}</b-form-checkbox>
        </b-form-group>
        <b-button block variant="primary" @click="onSubmit">Submit</b-button>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  AddUpdateCityService,
  GetCityListService,
  GetCountryListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { UserService } from "@/apiServices/storageService";
export default {
  data() {
    return {
      form: {
        city_id: null,
        country_id: null,
        city_name: null,
        city_is_active: true,
      },
      searchValue: null,
      showSideBar: false,
      isBusy: false,
      fields: [
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "city_name",
          label: "City Name",
          sortable: true,
        },
        {
          key: "country_name",
          label: "Country Name",
          sortable: true,
        },

        {
          key: "city_is_active",
          label: "City Status",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      countryListArray: [],
      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      },
      debounce: null,
    };
  },

  computed: {
    is_add() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "create");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_update() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "update");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_read() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "read");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  components: {
    vSelect,
  },
  beforeMount() {
    this.getCityList();
  },
  methods: {
    async onAddCity() {
      this.showSideBar = true;
      if (!this.countryListArray.length) {
        this.getCountryList();
      }
    },
    async onSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getCityList();
      }, 1000);
    },
    async onEditItem(item) {
      if (!this.countryListArray.length) {
        this.getCountryList();
      }
      Object.keys(item).map((x) => {
        this.form[x] = item[x];
        if (x == "city_is_active") {
          this.form[x] = item[x] == "Y" ? true : false;
        }
      });

      this.showSideBar = true;
    },
    async getCityList() {
      try {
        this.isBusy = true;
        const response = await GetCityListService({
          limit: this.pagination.limit,
          currentPage: this.pagination.currentPage,
          search: this.searchValue,
          isMaster: "Y",
        });
        if (response.data.status) {
          this.items = response.data.Records.data;
          if (!isNaN(response.data.Records.pagination.total)) {
            this.pagination.totalRows = response.data.Records.pagination.total;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
    async onSubmit() {
      try {
        this.form.city_is_active = this.form.city_is_active == true ? "Y" : "N";
        const response = await AddUpdateCityService(this.form);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
          this.showSideBar = false;
          this.getCityList();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error, "error");
      }
    },
    onChange() {
      this.$nextTick(() => {
        this.getCityList();
      });
    },
    async getCountryList() {
      try {
        const response = await GetCountryListService({
          limit: 1000,
        });
        if (response.data.status) {
          this.countryListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
