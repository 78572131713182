<template>
  <div v-if="VUE_APP_SEATING">
    <b-embed
      style="height: 1080px"
      type="iframe"
      aspect="16by9"
      :src="VUE_APP_SEATING"
      allowfullscreen
    ></b-embed>
  </div>
</template>

<script>
export default {
  data() {
    return {
      VUE_APP_SEATING: process.env.VUE_APP_SEATING,
    };
  },
  components: {},

  methods: {},
  mounted() {
    console.log(process.env);
  },
};
</script>

<style lang="scss" scoped></style>
