<template>
  <div>
    <section>
      <b-row>
        <b-col cols="12" md="4"
          ><b-form-input
            v-model="searchValue"
            @input="onSearch"
            placeholder="search pass name..."
          ></b-form-input
        ></b-col>
        <b-col cols="6" md="6">
          <b-form-radio-group
            id="radio-group-1"
            @input="onSearch"
            v-model="selectedEventType"
            :options="optionsEventTypes"
            name="radio-options"
          ></b-form-radio-group>
        </b-col>
        <b-col v-if="is_add" cols="6" md="2">
          <b-button block @click="onAddPass" variant="primary"
            >Create Pass</b-button
          >
        </b-col>
      </b-row>
    </section>
    <b-card no-body header-tag="header" footer-tag="footer">
      <b-card-text>
        <b-table
          bordered
          responsive
          :fields="fields"
          :items="items"
          :busy="isBusy"
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(sr)="data">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
          </template>

          <template #cell(pass_is_active)="data">
            {{ data.item.pass_is_active == "Y" ? "Active" : "Disabled" }}
          </template>

          <template v-if="is_update" #cell(action)="data">
            <b-button-group size="sm"
              ><b-button
                @click="onEditItem(data.item)"
                size="sm"
                variant="outline-primary"
                >Edit</b-button
              >
              <!-- <b-button
                @click="onOtherInfo(data.item)"
                size="sm"
                variant="outline-warning"
                >Events/Movies for Discount</b-button
              > -->
            </b-button-group>
          </template>
        </b-table>
      </b-card-text>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { GetPassListService } from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { UserService } from "@/apiServices/storageService";
export default {
  data() {
    return {
      searchValue: null,

      isBusy: false,
      fields: [
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "pass_name",
          label: "Pass Name",
          sortable: true,
        },
        {
          key: "pass_type",
          label: "Pass Type",
          sortable: true,
        },
        {
          key: "total_available_pass",
          label: "Total Pass",
          sortable: true,
        },
        {
          key: "pass_validity_from",
          label: "pass validity from",
          sortable: true,
        },
        {
          key: "pass_validity_to",
          label: "pass validity to",
          sortable: true,
        },
        {
          key: "pass_amount",
          label: "Pass Price",
          sortable: true,
        },
        {
          key: "curr_code",
          label: "Payment Currency",
          sortable: true,
        },

        {
          key: "discount_type",
          label: "discount_type",
          sortable: true,
        },
        {
          key: "pass_discount_value",
          label: "discount value %",
          sortable: true,
        },

        {
          key: "pass_is_active",
          label: "Pass Status",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      },
      debounce: null,
      selectedEventType: "Y",
      optionsEventTypes: [
        { text: "Active Pass", value: "Y" },
        { text: "Disabled Pass", value: "N" },
        { text: "All Pass", value: "" },
      ],
    };
  },
  computed: {
    is_add() {
      return true;
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "create");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_update() {
      return true;
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "update");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_read() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "read");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    this.getPassList();
  },
  methods: {
    async onOtherInfo(item) {
      // this.$router.push({
      //   name: "passdiscounts",
      //   query: { pass_id: item.pass_id },
      // });
    },
    async onAddPass() {
      this.$router.push({
        name: "passform",
      });
    },
    async onSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getPassList();
      }, 1000);
    },
    async onEditItem(item) {
      this.$router.push({
        name: "passform",
        query: { pass_id: item.pass_id },
      });
    },
    async getPassList() {
      try {
        this.isBusy = true;
        const response = await GetPassListService({
          limit: this.pagination.limit,
          currentPage: this.pagination.currentPage,
          search: this.searchValue,
          selectedEventType: this.selectedEventType,
        });
        if (response.data.status) {
          this.items = response.data.Records;
          if (!isNaN(response.data.Pagination.total)) {
            this.pagination.totalRows = response.data.Pagination.total;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    onChange() {
      this.$nextTick(() => {
        this.getPassList();
      });
    },
  },
};
</script>
