<template>
  <div>
    <section>
      <b-row>
        <b-col cols="12" md="4"
          ><b-form-input
            v-model="searchValue"
            @input="onSearch"
            placeholder="search..."
          ></b-form-input
        ></b-col>
        <b-col cols="6" md="6"> </b-col>
        <b-col v-if="is_add" cols="6" md="2">
          <b-button block @click="showSideBar = true" variant="primary">
            Add Country
          </b-button>
        </b-col>
      </b-row>
    </section>
    <b-card no-body header-tag="header" footer-tag="footer">
      <b-card-text>
        <b-table
          bordered
          responsive
          :fields="fields"
          :items="items"
          :busy="isBusy"
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(sr)="data">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
          </template>

          <template #cell(country_is_active)="data">
            {{ data.item.country_is_active == "Y" ? "Active" : "Disabled" }}
          </template>

          <template v-if="is_update" #cell(action)="data">
            <b-button
              @click="onEditItem(data.item)"
              size="sm"
              variant="outline-primary"
              >Edit
            </b-button>
          </template>
        </b-table>
      </b-card-text>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
    <!-- no-close-on-esc
      no-close-on-backdrop -->
    <b-sidebar
      right
      v-model="showSideBar"
      id="sidebar-backdrop"
      title="Country"
      backdrop-variant="dark"
      backdrop
      shadow
    >
      <div class="px-2 py-1">
        <b-form-group label="Country Name">
          <b-form-input
            v-model="form.country_name"
            placeholder="Country Name"
            id="input-default"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Country Short Code">
          <b-form-input
            v-model="form.country_code"
            placeholder="Country Short Code"
            id="input-default"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Country Mobile Code">
          <b-form-input
            type="number"
            v-model="form.country_mob_code"
            placeholder="Country Mobile Code (eg 965)"
            id="input-default"
          ></b-form-input>
        </b-form-group>
        <b-card-group class="m-1" deck>
          <b-card
            border-variant="primary"
            :img-src="
              form.country_flag_upload
                ? BASE_URL + form.country_flag_upload
                : ''
            "
            img-alt="Event Small image"
            img-bottom
          >
            <b-card-text>
              <b-form-group label="Country Flag">
                <b-form-file
                  @input="onFileUpload(country_flag_upload_model)"
                  v-model="country_flag_upload_model"
                  plain
                ></b-form-file>
              </b-form-group>
            </b-card-text>
          </b-card>
        </b-card-group>
        <b-form-group label="Country Status">
          <b-form-checkbox v-model="form.country_is_active" switch>{{
            form.country_is_active == true ? "Active" : "Disabled"
          }}</b-form-checkbox>
        </b-form-group>
        <b-button block variant="primary" @click="onSubmit">Submit</b-button>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  AddUpdateCountryService,
  GetCountryListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

import { UserService } from "@/apiServices/storageService";

export default {
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      form: {
        country_id: null,
        country_name: null,
        country_code: null,
        country_mob_code: null,
        country_flag_upload: null,
        country_is_active: true,
      },
      country_flag_upload_model: null,
      searchValue: null,
      showSideBar: false,
      isBusy: false,
      fields: [
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "country_name",
          label: "Country Name",
          sortable: true,
        },
        {
          key: "country_code",
          label: "Country Short Code",
          sortable: true,
        },
        {
          key: "country_mob_code",
          label: "Country Mob Code",
          sortable: true,
        },
        {
          key: "country_is_active",
          label: "Country Status",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      },
      debounce: null,
      loading: false,
    };
  },
  computed: {
    is_add() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "create");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_update() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "update");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_read() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "read");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    this.getCountryList();
  },
  methods: {
    async onSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getCountryList();
      }, 1000);
    },
    async onEditItem(item) {
      Object.keys(item).map((x) => {
        this.form[x] = item[x];
        if (x == "country_is_active") {
          this.form[x] = item[x] == "Y" ? true : false;
        }
      });
      this.showSideBar = true;
    },
    async getCountryList() {
      try {
        this.isBusy = true;
        const response = await GetCountryListService({
          limit: this.pagination.limit,
          currentPage: this.pagination.currentPage,
          search: this.searchValue,
          isMaster: "Y",
        });
        if (response.data.status) {
          this.items = response.data.Records.data;
          if (!isNaN(response.data.Records.pagination.total)) {
            this.pagination.totalRows = response.data.Records.pagination.total;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
    async onSubmit() {
      try {
        this.form.country_is_active =
          this.form.country_is_active == true ? "Y" : "N";
        const response = await AddUpdateCountryService(this.form);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
          this.showSideBar = false;
          this.getCountryList();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error, "error");
      }
    },
    async onFileUpload(uploadVModelValue) {
      this.loading = true;
      try {
        const url = this.BASE_URL + "/admin/uploadimage";
        const formData = new FormData();
        formData.append("image", uploadVModelValue);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            authorization: sessionStorage.getItem("access_token"),
            "x-access-token": sessionStorage.getItem("access_token"),
          },
        };
        const response = await axios.post(url, formData, config);
        if (response.data.message) {
          this.form.country_flag_upload = response.data.path;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error in UploadFile ", err);
      }
    },
    onChange() {
      this.$nextTick(() => {
        this.getCountryList();
      });
    },
  },
};
</script>
