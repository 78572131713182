<template>
  <div>
    <section>
      <b-row>
        <!-- <b-col cols="12" md="2">
          <b-form-group label="Booking Code">
            <b-form-input
              v-model="filters.booking_code"
              placeholder="booking code"
            ></b-form-input> </b-form-group
        ></b-col> -->

        <b-col cols="12" md="3">
          <b-form-group label="Customer Email">
            <b-form-input
              v-model="filters.customer_email"
              placeholder="email"
            ></b-form-input> </b-form-group
        ></b-col>

        <b-col cols="12" md="3">
          <b-form-group label="Search"
            ><b-form-input
              v-model="filters.search"
              placeholder="search name/phone..."
            ></b-form-input> </b-form-group
        ></b-col>

        <b-col cols="12" sm="12" md="5"
          ><b-button
            @click="getPassBookList"
            class="align-button mr-1"
            variant="primary"
            >Search</b-button
          ><b-button
            @click="resetFilters"
            class="align-button mr-1"
            variant="outline-danger"
            >Reset</b-button
          >
          <!-- <b-button
            @click="onExportReport"
            class="align-button"
            variant="outline-success"
            >Export Data</b-button
          > -->
        </b-col>
      </b-row>
    </section>
    <b-badge variant="info">
      <b> Transactions : {{ pagination.totalRows }}</b></b-badge
    >
    <b-card no-body header-tag="header" footer-tag="footer">
      <b-card-text>
        <b-table
          small
          bordered
          responsive
          :fields="fields"
          :items="items"
          :busy="isBusy"
          outlined
          hover
          :tbody-tr-class="rowClass"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(sr)="data">
            <b-badge v-if="data.item.booking_is_active == 'N'" variant="danger"
              >Cancelled</b-badge
            >
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
          </template>
          <template #cell(booking_code)="data">
            <p>{{ data.item.booking_code }}</p>
            <b-badge
              pill
              :variant="data.item.ticket_sent == 'Y' ? 'success' : 'danger'"
              >{{
                data.item.ticket_sent == "Y" ? "Email Sent" : "Email Failed"
              }}</b-badge
            >
          </template>

          <template #cell(event_name)="data">
            <div class="text-nowrap">{{ data.item.event_name }}</div>
          </template>
          <template #cell(event_date)="data">
            {{
              moment(data.item.event_date).format("DD/MM/YYYY") +
              " " +
              data.item.event_time
            }}
          </template>
          <template #cell(seat_names)="data">
            <div class="text-nowrap">{{ data.item.seat_names }}</div>
          </template>
          <template #cell(event_time)>
            <div class="text-nowrap">Confirmed</div>
          </template>

          <template #cell(total_before_discount)="data">
            <div class="text-nowrap">
              {{ parseFloat(data.item.total_before_discount).toFixed(3) }}
            </div>
          </template>
          <template #cell(voucher_code)="data">
            <div class="text-nowrap">
              {{ data.item.voucher_code ? data.item.voucher_code : "---" }}
            </div>
          </template>

          <template #cell(discount_percent)="data">
            <div class="text-nowrap">
              {{
                data.item.discount_percent
                  ? data.item.discount_percent + "%"
                  : "---"
              }}
            </div>
          </template>

          <template #cell(total_price)="data">
            <div class="text-nowrap">
              <b> {{ data.item.total_price }}</b>
            </div>
          </template>

          <template #cell(discount_value)="data">
            <div class="text-nowrap">
              {{ parseFloat(data.item.discount_value).toFixed(3) }}
            </div>
          </template>

          <template #cell(booking_date_time)="data">
            {{
              moment(data.item.booking_date_time).format(
                "DD/MM/YYYY hh:mm:ss A"
              )
            }}
          </template>

          <template #cell(booking_type_name)="data">
            {{ data.item.booking_type_name == "cron" ? "Rebooked" : "Normal" }}
          </template>

          <template #cell(total_seats)="data">
            <div class="text-nowrap">
              <p>
                Total Seats:<b-badge class="ml-1" pill variant="primary">{{
                  data.item.total_seats
                }}</b-badge>
              </p>
              <p>
                Seats Scanned:<b-badge class="ml-1" pill variant="success">{{
                  data.item.seats_scanned
                }}</b-badge>
              </p>

              <p>
                Seats pending:<b-badge class="ml-1" pill variant="info">{{
                  data.item.seats_tobe_scanned
                }}</b-badge>
              </p>

              <!-- <b-button
                size="sm"
                @click="onScanActions(data.item)"
                block
                variant="warning"
                >Actions</b-button
              > -->
            </div>
          </template>
          <template #cell(c_email)="data">
            <div class="text-nowrap">
              <p>{{ data.item.c_email }}</p>
              <!-- <b-button
                size="sm"
                @click="onScanActions(data.item)"
                block
                variant="outline-warning"
                >Resend Ticket</b-button
              > -->
            </div>
          </template>
        </b-table>
      </b-card-text>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
    <b-modal id="bv-modal-eventinfo" hide-footer>
      <template #modal-title>Resend Email Ticket </template>
      <div>
        <b-form-group label="Customer Email">
          <b-form-input
            v-model="resend_customer_email"
            :id="`type-remove`"
          ></b-form-input>
        </b-form-group>
      </div>
      <b-button variant="primary" class="mt-3" block @click="onScanSubmit"
        >Submit</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import {
  GetPassTransactionListService,
  GetEventListService,
  ResendEmailTicketService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import vSelect from "vue-select";

export default {
  data() {
    return {
      moment: moment,
      BASE_URL: process.env.VUE_APP_BASEURL,
      isBusy: false,
      selectedScanTransaction: null,
      fields: [
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "c_email",
          label: "customer email",
          sortable: true,
        },

        {
          key: "c_name",
          label: "customer name",
          sortable: true,
        },
        {
          key: "c_country_code",
          label: "country code",
          sortable: true,
        },
        {
          key: "c_phone_number",
          label: "customer phone",
          sortable: true,
        },
        {
          key: "pass_name",
          label: "pass name",
          sortable: true,
        },

        {
          key: "pass_price",
          label: "Pass Amount",
          sortable: true,
          variant: "info",
        },
        {
          key: "pass_tax_percent",
          label: "Pass Tax %",
          sortable: true,
          variant: "info",
        },
        {
          key: "pass_tax_value",
          label: "Pass Tax Amount",
          sortable: true,
          variant: "info",
        },
        {
          key: "pass_total_price",
          label: "Pass Total Amount",
          sortable: true,
          variant: "info",
        },
        {
          key: "currency",
          label: "currency",
          sortable: true,
          variant: "info",
        },
        {
          key: "pass_discount_percent",
          label: "pass discount %",
          sortable: true,
        },
        {
          key: "pass_valid_days",
          label: "pass valid days",
          sortable: true,
        },

        {
          key: "booking_date_time",
          label: "booking date time",
          sortable: true,
        },

        {
          key: "reservation_id",
          label: "ReservationID",
          sortable: true,
        },
        {
          key: "payment_transaction_id",
          label: "Payment Transaction ID",
          sortable: true,
        },
      ],
      items: [],
      pagination: {
        currentPage: 1,
        limit: 15,
        totalRows: 0,
      },
      debounce: null,
      selectedScan: "0",
      add_scan_ticket_count: 0,
      remove_scan_ticket_count: 0,
      optionsScan: [
        { text: "Remove from Scan Tickets", value: "0" },
        { text: "Add to Scan Tickets", value: "1" },
      ],
      filters: {
        event_ids: [],
        booking_code: null,
        customer_email: null,
        promo_code: null,
        search: null,
        selectedEventType: "Y",
      },
      eventListData: [],

      optionsEventTypes: [
        { text: "Active Events", value: "Y" },
        { text: "Disabled Events", value: "N" },
        { text: "All Events", value: "" },
      ],
      loading: false,
      resend_customer_email: null,
    };
    //
  },
  components: {
    vSelect,
  },
  beforeMount() {
    this.getPassTransactionList();
    this.getEventList();
  },
  methods: {
    async onExportReport() {
      let url = null;
      let excelName = "";

      let payload = JSON.stringify(this.filters);

      const anchorElement = document.createElement("a");
      document.body.appendChild(anchorElement);
      anchorElement.style.display = "none";

      url = this.BASE_URL + `/admin/exportBookingReport?payload=${payload}`;

      fetch(url, {
        headers: {
          "content-type": "application/Json",
          authorization: sessionStorage.getItem("access_token"),
          "x-access-token": sessionStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          excelName =
            "Booking Report-" + moment().format("DD/MM/YYYY") + ".xlsx";

          //console.log(blob);
          var url = window.URL.createObjectURL(blob);
          anchorElement.href = url;
          anchorElement.download = excelName;
          anchorElement.click();

          window.URL.revokeObjectURL(url);
          // window.open(_url, "_blank").focus(); // window.open + focus
        })
        .catch((err) => {
          console.log(err);
        });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.booking_is_active === "N") return "table-danger";
    },
    async resetFilters() {
      this.filters = {
        event_ids: [],
        booking_code: null,
        customer_email: null,
        promo_code: null,
        search: null,
        selectedEventType: "Y",
      };
      this.getPassTransactionList();
    },
    async getPassTransactionList() {
      try {
        this.isBusy = true;
        const response = await GetPassTransactionListService({
          limit: this.pagination.limit,
          currentPage: this.pagination.currentPage,
          filters: this.filters,
        });
        if (response.data.status) {
          this.items = response.data.Records.data;
          if (!isNaN(response.data.Records.pagination.total)) {
            this.pagination.totalRows = response.data.Records.pagination.total;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
    async getEventList() {
      this.eventListData = [];
      try {
        const response = await GetEventListService({
          limit: 5000,
          // country_id: this.form.country_id,
        });
        if (response.data.status) {
          this.eventListData = response.data.Records;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async onScanSubmit() {
      try {
        let payload = {
          booking_id: this.selectedScanTransaction
            ? this.selectedScanTransaction.booking_id
            : null,
          resend_customer_email: this.resend_customer_email,
        };
        const response = await ResendEmailTicketService(payload);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ticket Email Sent!",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }

        this.$bvModal.hide("bv-modal-eventinfo");
      } catch (error) {
        console.log(error);
      }
    },
    onScanChange() {
      this.add_scan_ticket_count = 0;
      this.remove_scan_ticket_count = 0;
    },
    onScanActions(selectedItem) {
      this.selectedScanTransaction = selectedItem;
      this.resend_customer_email = selectedItem.c_email;

      console.log(this.resend_customer_email);
      this.$bvModal.show("bv-modal-eventinfo");
    },

    onChange() {
      this.$nextTick(() => {
        this.getPassTransactionList();
      });
    },
  },
};
</script>
