<template>
  <div>
    <section>
      <b-row>
        <b-col cols="12" md="4"
          ><b-form-input
            v-model="searchValue"
            @input="onSearch"
            placeholder="search..."
          ></b-form-input
        ></b-col>
        <b-col cols="6" md="4"> </b-col>
        <b-col v-if="is_add" cols="6" md="4">
          <b-button block @click="onAddOrg" variant="primary"
            >Create New Organization</b-button
          ></b-col
        >
      </b-row>
    </section>
    <b-card no-body header-tag="header" footer-tag="footer">
      <b-card-text>
        <b-table
          bordered
          responsive
          :fields="fields"
          :items="items"
          :busy="isBusy"
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(sr)="data">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
          </template>

          <template #cell(org_is_active)="data">
            {{ data.item.org_is_active == "Y" ? "Active" : "Disabled" }}
          </template>

          <template #cell(org_website_names)="data">
            <div v-if="data.item.org_website_names">
              <h5
                v-for="(web, ind) in data.item.org_website_names.split(',')"
                :key="ind"
              >
                <b-badge variant="success">{{ web }}</b-badge>
              </h5>
            </div>
          </template>

          <template v-if="is_add" #cell(action)="data">
            <b-button
              @click="onLinkWebSite(data.item)"
              size="sm"
              variant="outline-primary"
              >Link Website</b-button
            >
          </template>
        </b-table>
      </b-card-text>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
    <b-modal id="bv-modal-example" hide-footer>
      <template #modal-title>
        Enter Website URL (eg - <b>https://www.example.com</b>)
      </template>
      <div class="d-block text-center">
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            variant="primary"
            v-for="(item, index) in website_url_array"
            :key="index"
            ><b-form-input v-model="item.value" placeholder="website url">
            </b-form-input>
            <feather-icon
              class="ml-1"
              @click="onRemoveWebsite(index)"
              icon="DeleteIcon"
              size="20"
          /></b-list-group-item>

          <b-list-group-item>
            <feather-icon @click="onAddMore" icon="PlusIcon" size="25" />
          </b-list-group-item>
        </b-list-group>
      </div>
      <b-button
        :disabled="!website_url_array.length"
        variant="success"
        v-if="is_add"
        @click="submitlinkWebsite"
        class="mt-3"
        block
        >Link Website</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import {
  GetOrganizationListService,
  AddUpdateOrgWebsiteService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { UserService } from "@/apiServices/storageService";
import store from "@/store";
export default {
  data() {
    return {
      searchValue: null,

      isBusy: false,
      fields: [
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "org_name",
          label: "Organization Name",
          sortable: true,
        },

        {
          key: "org_website_names",
          label: "Websites Linked",
          sortable: true,
        },

        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      },
      debounce: null,
      org_id: null,
      website_url_array: [{ text: "", value: null }],
    };
  },
  mounted() {
    let userData = store.getters["user/getUserDetails"];
    if (userData.is_super_admin == "Y") {
    } else {
      window.location.replace("/");
    }
  },
  computed: {
    is_add() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "create");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_update() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "update");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_read() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "read");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    this.getOrgList();
  },
  methods: {
    async onAddOrg() {
      this.$router.push({
        name: "organizationform",
      });
    },
    async onSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getOrgList();
      }, 1000);
    },
    async onLinkWebSite(item) {
      this.website_url_array = [];
      if (item.org_website_names) {
        let name = item.org_website_names.split(",");
        for (let n of name) {
          this.website_url_array.push({ text: "", value: n });
        }
      } else {
        this.website_url_array = [];
      }
      this.org_id = item.org_id;
      this.$bvModal.show("bv-modal-example");
    },

    async onAddMore() {
      this.website_url_array.push({ text: "", value: null });
    },
    async onRemoveWebsite(index) {
      this.website_url_array.splice(index, 1);
    },
    async submitlinkWebsite() {
      try {
        this.isBusy = true;

        const response = await AddUpdateOrgWebsiteService({
          org_id: this.org_id,
          website_url: this.website_url_array,
        });
        if (response.data.status) {
          this.$bvModal.hide("bv-modal-example");
          this.getOrgList();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    async getOrgList() {
      try {
        this.isBusy = true;
        const response = await GetOrganizationListService({
          limit: this.pagination.limit,
          currentPage: this.pagination.currentPage,
          search: this.searchValue,
        });
        if (response.data.status) {
          this.items = response.data.Records.data;
          this.items = this.items.filter((item) => item.role_id != 3);
          if (!isNaN(response.data.Records.pagination.total)) {
            this.pagination.totalRows = response.data.Records.pagination.total;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    onChange() {
      this.$nextTick(() => {
        this.getOrgList();
      });
    },
  },
};
</script>
